<template>

    <div class="page page-sidebar bg-4">
        <notifications/>
        <lego-side-bar class="text-white bg-primary" @link-event="linkEvent" drop-down-animation="zoom" :items="menu"
                       logo-text="RED CARPET"
                       :colored="false" has-separator
                       @logo-clicked="redirect">
        </lego-side-bar>
        <div class="page-content-outer">
            <div class="bg-3 nav-contain">
                <lego-nav-bar logo-text="" drop-down-animation="bounce" hover-effect="0"
                              class="headerFixed nav container text-white bg-2" shadow="0" :items="menu1"/>
            </div>
            <!--            </lego-nav-bar>-->
            <div class="page-content py-7 px-3">
                <div class="thin-scrollbar">
                    <transition name="fade"
                                enter-active-class="animated fadeIn"
                                leave-active-class="animated fadeOut" mode="out-in">
                        <router-view>

                        </router-view>
                    </transition>
                </div>
            </div>
            <div class="bg-primary-900 p-3">
                © {{ year }} Admin
                <!--                <btn @click="testNotification" text="click"></btn>-->
            </div>
        </div>
    </div>
</template>

<script>
import LegoNavBar from 'lego-framework/src/components/NavBar';
import LegoSideBar from 'lego-framework/src/components/SideNavBar';
import getMenu from '../data/menu';
import getMenu1 from '../data/navMenu';
import urls from '../data/urls';
import axios from 'secure-axios';

export default {
    components: {
        LegoNavBar,
        LegoSideBar
    },
    data () {
        return {
            menu: getMenu(),
            menu1: getMenu1(),
            value: '',
            year: new Date().getFullYear()
        };
    },
    methods: {
        testNotification () {
            this.$notify('Successfully Added', 'Success',
                {
                    type: 'success'
                }
            );
        },
        linkEvent (name) {
            alert('name');
            console.log('name', name);
            if (name === 'logout') {
                localStorage.clear();
                this.signOut();
                axios.get(urls.auth.logout);
                this.$router.push('/login/');
            }
            // console.log(name);
            if (name === 'update-password') {
                this.$router.push('/update-password/');
            }
        }

    },
    metaInfo: {
        titleTemplate: '%s - Project Name',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    name: 'MainLayout'
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}
</style>
